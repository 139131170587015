@import 'variables';
.setting-container{
    height: 100%;
    .setting-content {
        background: white;
        width: 100%;
        max-width: 800px;
    
        @media #{$screenLG} {
            max-width: 1000px;
        }
    
        @media #{$screenXS} {
            padding: 10px;
        }
    
        margin: 40px auto 30px auto;
        padding: 20px;
        border-bottom: 2px solid #e7e7e7;
    
        header {
            display: flex;
            flex-direction: column;
    
            .group {
                display: flex;
                margin: 0px 0px 7px 5px;
            }
        }
    
        table {
            width: 100%;
    
            th {
                background-color: #f6f6f6;
                font-size: 16px;
            }
        }
    
        .empty-show {
            text-align: center;
            margin-top: 60px;
            margin-bottom: 60px;
            font-size: 17px;
            color: $accentColor;
    
            .cloud-off-icon {
                height: 50px;
                width: 50px;
                font-size: 50px;
            }
        }
    
        .mat-column-office,
        .mat-column-group {
            text-align: center;
        }
    
        .mat-column-action {
            text-align: right;
        }
    
        .secondary-text {
            color: rgba(#062743, .55);
        }
    
        td.mat-cell,
        td.mat-footer-cell,
        th.mat-header-cell {
            height: 56px;
        }
    
        .mat-header-cell {
            color: rgb(0, 0, 0);
        }
    
        #infinite-scroller {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px;
        }
    
        .field-group {
            width: 100%;
    
            @media (min-width: 1240px) {
                display: flex;
                align-items: center;
            }
    
            mat-form-field {
                width: 100%;
    
                &:first-child {
                    margin-right: 14px;
                }
    
                &:nth-child(3) {
                    margin-left: 14px;
    
                    @media (max-width: 1240px) {
                        margin-left: 0px;
                    }
                }
    
                .mat-form-field-infix {
                    width: 100% !important;
                }
    
                .mat-form-field-flex {
                    align-items: end;
                }
    
                .mat-form-field-prefix {
                    margin-right: 5px;
                    margin-bottom: 5px;
                }
    
                .mat-select-value,
                input.mat-input-element {
                    line-height: 33px;
                }
    
                button.mat-focus-indicator {
                    margin-bottom: 3px;
                }
            }
        }
    }
}
