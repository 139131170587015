
@import 'variables';
$blue: #0056ac;
$dblue: #002271;
$gray: #6b6b6b;
$dgray: #333;
$lgray: #bbb;
.document-item {
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(black, .1);
  transition: max-height .1s ease, box-shadow .1s ease;
  overflow: hidden;
  display: flex;
  background-color: white;
  &.save-only-item{
    background-color: #E5F8FF;
  }
  .doc-priority{
    min-width: 130px;
    background-repeat: no-repeat;
    background-position: center calc(50% - 15px);
    background-size: 50px;
    margin: 15px 0;
    border-right: 1px solid $lgray;
    position: relative;
    line-height: 1.6;
    @media #{$screenXS} {
      min-width: 75px;
    }
    @media #{$screenLG}  {
      min-width: 150px;
    }
    &.priority_1{
      background-image: url(/assets/images/priority_1.svg);
      label{
        color: #42be00;
      }
    }
    &.priority_2{
      background-image: url(/assets/images/priority_2.svg);
      label{
        color: #bf7d00;
      }
    }
    &.priority_3{
      background-image: url(/assets/images/priority_3.svg);
      label{
        color: #ff3030;
      }
    }
    label{
      display: block;
      width: 100%;
      text-align: center;
      position: absolute;
      top: calc(50% + 18px);
    }
    .save-only{
      text-align: center;
      position: absolute;
      bottom: 0;
      padding: 0 10px;
      left: calc(50% - 75px / 2);
      width: 75px;
      color: white;
      border-radius: 5px;
      &.digital{
        background-color: #0056ac;
      }
      &.hard{
        background-color: #008000;
      }
    }
  }
  .doc-content{
    flex-grow: 1;
    position: relative;
    padding: 15px;
   
    .item-header {
      overflow: hidden;
      padding-bottom: 10px;
  
      h3 {
        font-size: 18px;
        line-height: 26px;
        color: $blue;
        small{
          margin-left: 5px;
          white-space: nowrap;
          font-size: 16px;
          &.date {
            color: $gray;
          } 
          &.author {
            color: $accentColor;
          }
        }
      }
  
      .start {
        width: 100%;
        display: flex;
      }
  
      .header-title {
        width: calc(100% - 90px);
        line-height: 1.9;
        font-size: 20px;
        .doc_type{
          background-color: $accentDarkColor;
          color: white;
          font-size: 20px;
          padding: 2px; 
          border-radius: 50%;
          margin-right: 5px;
          &.in{
            &::after{
              content: "arrow_downward";
            }
          }
          &.out{
            &::after{
              content: "arrow_upward";
            }
          }
          &.internal{
            &::after{
              content: "refresh";
            }
          }    
        }
        .seen{
          color: $primaryColor;
          vertical-align: sub;
          margin-left: 5px;
        }
        .unseen{
          color: $lgray;
          vertical-align: sub;
          margin-left: 5px;
        }
      }
    }
  
    p.item-detail {
      margin: 0;
      margin-bottom: 15px;
      max-width: 700px;
      overflow: hidden;
      color: $dblue;
      font-size: 18px;
      line-height: 1.5;
    }
    p.reference{
      margin-top: -7px;
    }
    p.sender{
      color: $blue;
    }
    .item-footer {
      padding-top: 10px;
      border-top: 1px dashed $lgray;
      display: flex;
      div{
        flex-grow: 1;
        // flex: 1 1 0px; // to make all item has same width
        p{
          margin: 0;
          &.label{
            color: $gray;
            font-size: 15px;
            margin-bottom: 5px;
          }
          &.value{
            color: $dblue;
            font-size: 15px;
          }
          &.right{
            text-align: right;
            padding-right: 10px;
          }
          &.danger{
            color: red;
          }
        }
      }
      button{
        margin-left: auto;
      }
      
    }
  }
  
  &.new_created{
    .user-doc-type{
      background-color: #ce901d;
    }
  } 
  &.new_entry{
    .user-doc-type{
      background-color: #e4636a;
    }
  } 
  &.in_charge{
    .user-doc-type{
      display: none;
    }
   
  } 
  .user-doc-type, .doc-status {
    font-size: 13px;
    padding: 10px;
    width: 85px;
    text-align: center;
    color: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 10px 0 10px;
  }

  .doc-status{
    font-size: 20px;
    width: 60px;
    padding: 5px 10px;
    &.approve{
      background-color: green;
    }
    &.waiting{
      background-color: #ff8a14;
    }
  }

  &.readonly{
    .header-title{
      width: calc(100% - 70px) !important;
    }
  }
}