h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-style: normal;
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: $accentColor;
  text-decoration: none;
  &:visited {
    color: $accentDarkColor;
  }
  &:hover {
    color: $accentLightColor;
  }
  &:active {
    color: $primaryColor;
  }
}
