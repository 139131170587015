@import './static-variables';

// Colors
$accentDarkColor: #ecb22d;
$accentColor: lighten( $accentDarkColor, 10%);
$accentLightColor:  lighten( $accentDarkColor, 20%);
$accentSuperLightColor:lighten( $accentDarkColor, 30%);

$primaryColor: #254868;
$accentLight: #DDF5EA;
$primaryGrayColor: #5b5b5b;
$warnColor: #FF8A14;
$backgroundColor: #fff;





// Typeface
$fontFamily: unquote('Battambang, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif');
